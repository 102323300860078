import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/common.scss";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import axios from "axios";
import VueAxios from "vue-axios";
import * as echarts from "echarts";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import vueEsign from "vue-esign";

const app = createApp(App);

app.config.globalProperties.$echarts = echarts;

app
  .use(vueEsign)
  .use(store)
  .use(router)
  .use(VueViewer)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(VueAxios, axios)
  .mount("#app");
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

axios
  .get("/init.json")
  .then((res) => {
    axios.defaults.baseURL = res.data.baseUrl;
    sessionStorage.setItem("clientUrl", res.data.clientUrl);
  })
  .catch((error) => {
    console.log(error);
  });

axios.interceptors.request.use(function (config) {
  // const tokenStr = localStorage.getItem("token");
  const tokenStr = sessionStorage.getItem("token");

  config.headers.Authorization = `${tokenStr}`;
  return config;
});
