<template>
  <div class="common-layout">
    <el-container>
      <el-header><NavBar /></el-header>
      <el-main><AppMain /></el-main>
    </el-container>
  </div>
</template>
<script>
import AppMain from "./AppMain.vue";
import NavBar from "./NavBar.vue";
export default {
  name: "LayOut",
  components: {
    NavBar,
    AppMain,
  },
  methods: {
    goICP() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
  },
};
</script>
