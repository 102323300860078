<template>
  <div>
    <div id="header">
      <div id="header_main_icon"></div>
      <div id="header_main_title">广东省消毒供应质控平台</div>
      <div id="header_sub_title">
        Guangdong Disinfection Supply Quality Management And Control Platform
      </div>
    </div>
    <div id="main">
      <div
        id="main_row_dsqc"
        @click="dsqcLogin"
        style="background: url('./qc.png'); no-repeat;background-size: 100% 100%;cursor:pointer;"
      >
        <div id="main_row_dsqc_text">质控中心</div>
        <div id="main_row_dsqc_text_bottom">入口</div>
        <div id="main_row_dsqc_footer">现场评价 / 质量分析</div>
      </div>

      <div
        id="main_row_org"
        @click="hospitalLogin"
        style="background: url('./hos.png'); no-repeat;background-size: 100% 100%;cursor:pointer;"
      >
        <div id="main_row_org_text" style="left: 4vw">医院</div>
        <div id="main_row_org_text_bottom">入口</div>
        <div id="main_row_org_footer">指标上报 / 评估反馈</div>
      </div>

      <el-dialog
        v-model="qsdcDialog"
        id="qsdc_dialog"
        @closed="showForm = false"
        :style="qsdcDialogStyle"
      >
        <div v-show="showForm == false" style="height: 100%; width: 100%">
          <div class="dialog_title">质控中心入口</div>

          <div id="client_entrance">
            <div style="margin-left: 25px" @click="clientEntrance">
              现场评分
              <div class="tips">（建议使用平板）</div>
            </div>
          </div>

          <div id="manage_entrance" @click="manageEntrance">
            <div style="margin-left: 25px">
              后台管理
              <div class="tips">（建议使用电脑）</div>
            </div>
          </div>
        </div>
        <div v-show="showForm == true" style="height: 100%; width: 100%">
          <el-form :model="loginForm" style="margin-left: 55%; width: 43%">
            <div>
              <h1>{{ title }}登 录</h1>
            </div>
            <br /><br />
            <el-form-item>
              <el-input
                v-model="loginForm.phone"
                placeholder="账号"
                prefix-icon="user"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="loginForm.password"
                placeholder="密码"
                prefix-icon="lock"
                type="password"
                @keyup.enter="login"
                clearable
              />
            </el-form-item>
            <el-button type="primary" @click="login" style="width: 100%"
              >登 录</el-button
            >
            <br /><br />
            <el-button type="danger" @click="goBack" style="width: 100%"
              >返 回</el-button
            >
            <br />
            <el-checkbox
              v-model="loginForm.isSaveMe"
              label="记住我"
              size="large"
            />
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        v-model="hospitalDialog"
        id="hos_dialog"
        :style="hosDialogStyle"
      >
        <el-form :model="loginForm" style="margin-left: 55%; width: 43%">
          <div>
            <h1>医院入口</h1>
          </div>
          <br /><br />
          <el-form-item>
            <el-input
              v-model="loginForm.phone"
              placeholder="账号"
              prefix-icon="user"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="loginForm.password"
              placeholder="密码"
              prefix-icon="lock"
              type="password"
              @keyup.enter="orgLogin"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="orgLogin" style="width: 100%"
              >登 录</el-button
            >
            <br />
            <el-checkbox
              v-model="loginForm.isSaveMe"
              label="记住我"
              style="margin-top: 8px"
            />
          </el-form-item>

          <el-button
            type="primary"
            @click="register"
            link
            style="float: left; margin-top: 3vh"
            >注 册</el-button
          >
          <el-button
            type="danger"
            link
            style="float: right; margin-top: 3vh; margin-right: -10px"
            @click="
              this.dialogFormVisible = true;
              this.hospitalDialog = false;
            "
            >忘记密码？</el-button
          >
        </el-form>
      </el-dialog>

      <el-dialog
        v-model="dialogFormVisible"
        style="width: 30%; height: "
        @close="clearResetPwdValidate('resetPwdDialog')"
      >
        <template #header>
          <div style="font-weight: 600; text-align: left">重置密码</div>
        </template>
        <!-- <div style="font-size: 10pt; text-align: left">
            验证码将会发送至你的手机
          </div> -->
        <el-form
          :model="form"
          label-position="right"
          ref="resetPwdDialog"
          :rules="rules"
        >
          <el-form-item
            label="手机号:"
            :label-width="formLabelWidth"
            prop="phone"
          >
            <el-input
              v-model="form.phone"
              autocomplete="off"
              placeholder="请填写您的注册手机号"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="验证码:"
            :label-width="formLabelWidth"
            prop="code"
          >
            <el-input
              v-model="form.code"
              autocomplete="off"
              placeholder="验证码1分钟内有效"
            >
              <template #append>
                <el-button
                  @click="sendMsg(form)"
                  type="success"
                  :disabled="isSendable || countdown > 0"
                >
                  {{
                    isSendable ? `${countdown}秒后重试` : "发送验证码"
                  }}</el-button
                >
              </template>
            </el-input>
          </el-form-item>
        </el-form>
        <template #footer>
          <div>
            <span style="float: left; font-size: 10pt; color: #8491a5"
              >验证码将以短信形式发送至您的手机</span
            >
            <el-button type="primary" @click="verify('resetPwdDialog')"
              >提 交</el-button
            >
            <el-button @click="cancel">取 消</el-button>
          </div>
        </template>
      </el-dialog>
    </div>

    <el-button id="foot_btn" link type="primary" @click="goICP"
      >京ICP备2023010364号
    </el-button>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
// import md5 from "js-md5";
export default {
  data() {
    return {
      userId: null,
      title: null,
      qsdcDialog: false,
      hospitalDialog: false,
      showForm: false,
      client: false,
      manage: false,
      dialogFormVisible: false,
      form: [],
      loginForm: {
        phone: "",
        password: "",
        isSaveMe: false,
      },
      screenWidth: document.body.clientWidth,
      isMobile: null,
      qsdcDialogStyle:
        "background: url('./loginDialogBg.jpg');background-size: 50% 100%;background-repeat: no-repeat;background-color: white;height:60%",
      hosDialogStyle:
        "background: url('./loginDialogBg.jpg');background-size: 50% 100%;background-repeat: no-repeat;background-color: white; height: 60%;",
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern:
              /^(13[0-9]|14[5-9]|15[0-3,5-9]|16[6]|17[0-8]|18[0-9]|19[8,9])\d{8}$/,
            message: "请输入正确的国内手机号",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            pattern: /^\d{4}$/,
            message: "请输入4位数字验证码",
            trigger: "blur",
          },
        ],
      },
      isSendable: false,
      countdown: 0,
    };
  },

  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          // ElMessage.info("宽度：" + that.screenWidth);
          // 判断是否为移动端,是则调整布局
          if (that.isMobile) {
            location.reload();
            that.changeLayout(that.screenWidth);
          }

          that.timer = false;
        }, 400);
      }
    },
  },

  mounted() {
    this.isMobile = this._isMobile();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    if (this.isMobile) {
      this.changeLayout(document.body.clientWidth);
    }

    if (localStorage.getItem("phone")) {
      this.loginForm.phone = localStorage.getItem("phone");
      this.loginForm.password = localStorage.getItem("password");
      this.loginForm.isSaveMe = true;
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    changeLayout(width) {
      if (width < 1000) {
        document.getElementById("header_main_title").style.fontSize = "5vw";
        document.getElementById("header_main_title").style.left = "25vw";
        document.getElementById("header_main_title").style.top = "11vh";

        document.getElementById("header_sub_title").style.fontSize = "2vw";
        document.getElementById("header_sub_title").style.left = "17vw";
        document.getElementById("header_sub_title").style.top = "18vh";

        document.getElementById("header_main_icon").style.width = "10vw";
        document.getElementById("header_main_icon").style.height = "7vh";
        document.getElementById("header_main_icon").style.left = "45vw";
        document.getElementById("header_main_icon").style.top = "3vh";

        document.getElementById("main_row_dsqc").style.width = "65vw";
        document.getElementById("main_row_dsqc").style.height = "20vh";
        document.getElementById("main_row_dsqc").style.left = "18vw";
        document.getElementById("main_row_dsqc").style.top = "27vh";

        document.getElementById("main_row_org").style.width = "65vw";
        document.getElementById("main_row_org").style.height = "20vh";
        document.getElementById("main_row_org").style.left = "18vw";
        document.getElementById("main_row_org").style.top = "53vh";

        document.getElementById("main_row_dsqc_text").style.fontSize = "4vw";
        document.getElementById("main_row_dsqc_text").style.left = "3vw";
        document.getElementById("main_row_dsqc_text").style.top = "4vh";

        document.getElementById("main_row_dsqc_text_bottom").style.fontSize =
          "4vw";
        document.getElementById("main_row_dsqc_text_bottom").style.left = "7vw";
        document.getElementById("main_row_dsqc_text_bottom").style.top = "8vh";

        document.getElementById("main_row_dsqc_footer").style.fontSize =
          "1.5vw";
        document.getElementById("main_row_dsqc_footer").style.left = "4.5vw";
        document.getElementById("main_row_dsqc_footer").style.top = "17vh";

        document.getElementById("main_row_org_text").style.fontSize = "4vw";
        document.getElementById("main_row_org_text").style.left = "7vw";
        document.getElementById("main_row_org_text").style.top = "4vh";

        document.getElementById("main_row_org_text_bottom").style.fontSize =
          "4vw";
        document.getElementById("main_row_org_text_bottom").style.left = "7vw";
        document.getElementById("main_row_org_text_bottom").style.top = "8vh";

        document.getElementById("main_row_org_footer").style.fontSize = "1.5vw";
        document.getElementById("main_row_org_footer").style.left = "4.5vw";
        document.getElementById("main_row_org_footer").style.top = "17vh";

        document.getElementById("foot_btn").style.fontSize = "1.8vw";
        document.getElementById("foot_btn").style.marginTop = "85vh";

        this.qsdcDialogStyle =
          "background: url('./loginDialogBg.jpg');background-size: 50% 100%;background-repeat: no-repeat;background-color: white;width:90%;height:50%";
        this.hosDialogStyle =
          "background: url('./loginDialogBg.jpg');background-size: 50% 100%;background-repeat: no-repeat;background-color: white;width:90%;height:50%";
      } else {
        document.getElementById("header_main_title").style.fontSize = "3vw";
        document.getElementById("header_main_title").style.left = "36.5vw";
        document.getElementById("header_main_title").style.top = "8vh";

        document.getElementById("header_sub_title").style.fontSize = "0.8vw";
        document.getElementById("header_sub_title").style.left = "36.5vw";
        document.getElementById("header_sub_title").style.top = "18vh";

        document.getElementById("header_main_icon").style.width = "10vw";
        document.getElementById("header_main_icon").style.height = "17vh";
        document.getElementById("header_main_icon").style.left = "27vw";
        document.getElementById("header_main_icon").style.top = "5vh";

        document.getElementById("main_row_dsqc").style.width = "38vw";
        document.getElementById("main_row_dsqc").style.height = "31.5vh";
        document.getElementById("main_row_dsqc").style.left = "8vw";
        document.getElementById("main_row_dsqc").style.top = "27vh";

        document.getElementById("main_row_org").style.width = "38vw";
        document.getElementById("main_row_org").style.height = "31.5vh";
        document.getElementById("main_row_org").style.left = "54vw";
        document.getElementById("main_row_org").style.top = "27vh";

        document.getElementById("main_row_dsqc_text").style.fontSize = "2vw";
        document.getElementById("main_row_dsqc_text").style.left = "2vw";
        document.getElementById("main_row_dsqc_text").style.top = "7vh";

        document.getElementById("main_row_dsqc_text_bottom").style.fontSize =
          "2vw";
        document.getElementById("main_row_dsqc_text_bottom").style.left = "4vw";
        document.getElementById("main_row_dsqc_text_bottom").style.top = "12vh";

        document.getElementById("main_row_dsqc_footer").style.fontSize =
          "0.8vw";
        document.getElementById("main_row_dsqc_footer").style.left = "2.2vw";
        document.getElementById("main_row_dsqc_footer").style.top = "26vh";

        document.getElementById("main_row_org_text").style.fontSize = "2vw";
        document.getElementById("main_row_org_text").style.left = "4vw";
        document.getElementById("main_row_org_text").style.top = "7vh";

        document.getElementById("main_row_org_text_bottom").style.fontSize =
          "2vw";
        document.getElementById("main_row_org_text_bottom").style.left = "4vw";
        document.getElementById("main_row_org_text_bottom").style.top = "12vh";

        document.getElementById("main_row_org_footer").style.fontSize = "0.8vw";
        document.getElementById("main_row_org_footer").style.left = "2.2vw";
        document.getElementById("main_row_org_footer").style.top = "26vh";

        document.getElementById("foot_btn").style.fontSize = "0.8vw";
        document.getElementById("foot_btn").style.marginTop = "80vh";

        this.qsdcDialogStyle =
          "background: url('./loginDialogBg.jpg');background-size: 50% 100%;background-repeat: no-repeat;background-color: white;width:60%;height:70%";
        this.hosDialogStyle =
          "background: url('./loginDialogBg.jpg');background-size: 50% 100%;background-repeat: no-repeat;background-color: white;width:60%;height:70%";
      }
    },
    register() {
      this.$router.push({
        path: "/hospital/register",
        query: { userId: this.userId },
      });
    },
    goICP() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
    dsqcLogin() {
      this.qsdcDialog = true;
    },
    hospitalLogin() {
      this.hospitalDialog = true;
    },
    clientEntrance() {
      this.title = " 现 场 评 分 ";
      this.client = true;
      this.showForm = true;
    },
    manageEntrance() {
      this.title = " 后 台 管 理  ";
      this.manage = true;
      this.showForm = true;
    },
    goBack() {
      this.manage = false;
      this.client = false;
      this.showForm = false;
    },
    login() {
      if (this.loginForm.isSaveMe) {
        localStorage.setItem("phone", this.loginForm.phone);
        localStorage.setItem("password", this.loginForm.password);
        // sessionStorage.setItem("phone", this.loginForm.phone);
        // sessionStorage.setItem("password", this.loginForm.password);
      } else {
        localStorage.removeItem("phone");
        localStorage.removeItem("password");
        // sessionStorage.removeItem("phone");
        // sessionStorage.removeItem("password");
      }

      this.axios
        .post("/api/user/login", {
          phone: this.loginForm.phone,
          pwd: this.loginForm.password,
        })
        .then((successResponse) => {
          let yy = new Date().getFullYear();
          let mm = new Date().getMonth() + 1;
          let dd = new Date().getDate();
          let loginTime =
            yy.toString() + "-" + mm.toString() + "-" + dd.toString();
          if (successResponse.data.code === 9000) {
            if (
              this.client == true &&
              (successResponse.data.data.role == 3 ||
                successResponse.data.data.role == 1 ||
                successResponse.data.data.role == 0)
            ) {
              const jwt = successResponse.headers["authorization"];
              this.$store.commit("SET TOKEN", jwt);

              this.$router.push({ path: "/client/form-manage" });
              // ElMessage.success("登录成功！");
              localStorage.setItem("loginTime", loginTime);
              localStorage.setItem("isLogined", 1);
              localStorage.setItem("id", successResponse.data.data.id);
              localStorage.setItem(
                "username",
                successResponse.data.data.username
              );
              localStorage.setItem("orgId", successResponse.data.data.orgId);
              localStorage.setItem(
                "orgName",
                successResponse.data.data.orgName
              );
            } else if (
              this.manage == true &&
              successResponse.data.data.role == 0
            ) {
              const jwt = successResponse.headers["authorization"];
              this.$store.commit("SET_TOKEN", jwt);

              this.$router.push({ path: "form/mark-form" });
              // ElMessage.success("登录成功！");
              localStorage.setItem("loginTime", loginTime);
              localStorage.setItem("isLogined", 1);
              localStorage.setItem("id", successResponse.data.data.id);
              localStorage.setItem(
                "username",
                successResponse.data.data.username
              );
              localStorage.setItem("orgId", successResponse.data.data.orgId);
              localStorage.setItem(
                "orgName",
                successResponse.data.data.orgName
              );
            } else if (
              this.hospitalDialog == true &&
              (successResponse.data.data.role == 3 ||
                successResponse.data.data.role == 2 ||
                successResponse.data.data.role == 0)
            ) {
              const jwt = successResponse.headers["authorization"];
              this.$store.commit("SET_TOKEN", jwt);
              this.$router.push({ path: "hospital/home" });
              // ElMessage.success("登录成功！");
              localStorage.setItem("loginTime", loginTime);
              localStorage.setItem("isLogined", 1);
              localStorage.setItem("id", successResponse.data.data.id);
              localStorage.setItem(
                "username",
                successResponse.data.data.username
              );
              localStorage.setItem("orgId", successResponse.data.data.orgId);
              localStorage.setItem(
                "orgName",
                successResponse.data.data.orgName
              );
            } else {
              ElMessage.error("权限不足，请使用管理员账号登录！");
            }
          } else {
            ElMessage.error("登录失败，请检查账号和密码");
          }
        })
        .catch((e) => {
          console.error("网络异常，请检查您的网络！" + e);
        });
    },
    orgLogin() {
      if (this.loginForm.isSaveMe) {
        localStorage.setItem("phone", this.loginForm.phone);
        localStorage.setItem("password", this.loginForm.password);
      } else {
        localStorage.removeItem("phone");
        localStorage.removeItem("password");
      }

      this.axios
        .post("/api/orgUser/login", {
          phone: this.loginForm.phone,
          pwd: this.loginForm.password,
        })
        .then((successResponse) => {
          if (successResponse.data.code == 9000) {
            this.userId = successResponse.data.data.id;
            if (
              successResponse.data.data.organization.state == 1 &&
              successResponse.data.data.state == 1
            ) {
              let yy = new Date().getFullYear();
              let mm = new Date().getMonth() + 1;
              let dd = new Date().getDate();
              let loginTime =
                yy.toString() + "-" + mm.toString() + "-" + dd.toString();

              const jwt = successResponse.headers["authorization"];
              this.$store.commit("SET_TOKEN", jwt);
              this.$router.push({ path: "hospital/home" });
              // ElMessage.success("登录成功！");
              localStorage.setItem("loginTime", loginTime);
              localStorage.setItem("isLogined", 1);
              localStorage.setItem("id", successResponse.data.data.id);
              localStorage.setItem(
                "username",
                successResponse.data.data.username
              );
              localStorage.setItem("orgId", successResponse.data.data.orgId);
              localStorage.setItem(
                "orgName",
                successResponse.data.data.orgName
              );
            }

            if (successResponse.data.data.state == 0) {
              ElMessage.warning("审核暂未通过");
              this.register();
            }

            if (successResponse.data.data.state == 3) {
              this.register();
            }
          } else {
            ElMessage.error(successResponse.data.msg);
          }
        })
        .catch((e) => {
          console.error("网络异常，请检查您的网络" + e);
        });
    },

    cancel() {
      this.dialogFormVisible = false;
      this.form = [];
    },
    sendMsg(form) {
      this.axios
        .post("/api/msg/verifySMS", {
          phone: form.phone,
          state: 0,
        })
        .then((res) => {
          // 从响应体中获取JWT
          const jwt = res.headers["authorization"]; // 将JWT存储在sessionStorage中

          if (jwt) {
            if (this.countdown > 0 || this.isSendable) {
              return; // 防止重复点击发送
            }

            this.isSendable = true;
            this.startCountdown();
            window.sessionStorage.setItem("token", jwt);
            this.$message({
              message: "验证码已发送，请查收短信",
              type: "success",
            });
          } else {
            this.$message.error(res.data.msg);
            this.isSendable = false;
          }
        });
    },

    startCountdown() {
      this.countdown = 60;
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(timer);
          this.isSendable = false;
        }
      }, 1000);
    },

    clearResetPwdValidate(resetPwdDialog) {
      this.$refs[resetPwdDialog].clearValidate();
    },
    verify(resetPwdDialog) {
      this.$refs[resetPwdDialog].validate((valid) => {
        if (valid) {
          // 从sessionStorage中获取JWT
          const jwt = window.sessionStorage.getItem("token");

          // 如果JWT存在，将其添加到请求头中
          const headers = jwt ? { Authorization: `Bearer ${jwt}` } : {};

          this.axios
            .post(
              "/api/msg/verify",
              { code: this.form.code, phone: this.form.phone },
              { headers }
            )
            .then((res) => {
              if (res.data.code == 9000) {
                this.$message({ message: res.data.data, type: "success" });
                this.dialogFormVisible = false;
                window.sessionStorage.clear("token");
              } else {
                this.$message.error(res.data.msg);
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.dialog_title {
  margin-left: 50%;
  font-size: 25pt;
  font-weight: 600;
}

#manage_entrance {
  margin: 5%;
  color: white;
  font-weight: 600;
  font-size: 25pt;
  margin-left: 55%;
  height: 70%;
  width: 43%;
  background-color: rgb(4, 7, 202);
  border-radius: 5px;
  letter-spacing: 25px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
}

#manage_entrance_mobile {
  margin: 5%;
  color: white;
  font-weight: 600;
  font-size: 15pt;
  margin-left: 55%;
  height: 50px;
  width: 43%;
  background-color: rgb(4, 7, 202);
  border-radius: 5px;
  letter-spacing: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

#client_entrance {
  margin: 5%;
  color: white;
  font-weight: 600;
  font-size: 25pt;
  margin-left: 55%;
  height: 70%;
  width: 43%;
  background-color: rgb(4, 122, 67);
  border-radius: 5px;
  letter-spacing: 25px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  cursor: pointer;
}

#client_entrance_mobile {
  margin: 5%;
  color: white;
  font-weight: 600;
  font-size: 15pt;
  margin-left: 55%;
  height: 50px;
  width: 43%;
  background-color: rgb(4, 122, 67);
  border-radius: 5px;
  letter-spacing: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.tips {
  font-size: 13px;
  height: 15px;
  color: white;
  width: 100%;
  letter-spacing: 0px;
  font-weight: 0;
}

.el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__wrapper,
.el-input-group__append div.el-select:hover .el-input__wrapper,
.el-input-group__prepend button.el-button,
.el-input-group__prepend button.el-button:hover,
.el-input-group__prepend div.el-select .el-input__wrapper,
.el-input__wrapper {
  background-color: #409eff;
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.el-input-group__prepend div.el-select:hover,
.el-input-group__append button.el-button:hover {
  background-color: #79bbff;
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
