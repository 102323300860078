<template>
  <div ref="main" style="width: 100%">
    <img
      src="/favicon1.ico"
      style="width: 30px; height: 30px; margin-left: 8px"
    />
    <div style="width: 280.36px">广东省消毒供应质控平台</div>
    <!-- <div style="width: 280.36px">消毒供应质控中心</div> -->
    <div style="width: 70%; text-align: left">
      <el-menu
        ref="menu"
        router
        active-text-color="#ffd04b"
        background-color="#373d41"
        text-color="#fff"
        mode="horizontal"
        :default-active="$route.path"
        style="width: 100%; marign-left: 8px; height: 6vh"
        unique-opened
        menu-trigger="click"
      >
        <el-sub-menu index="1">
          <template #title>
            <el-icon>
              <MessageBox />
            </el-icon>
            <span>评价表管理</span>
          </template>
          <el-menu-item index="/form/mark-form">
            <template #title>
              <el-icon>
                <MessageBox />
              </el-icon>
              <span>评分表</span>
            </template></el-menu-item
          >
          <!-- <el-menu-item index="/form/self-form">
            <template #title>
              <el-icon>
                <MessageBox />
              </el-icon>
              <span>自评表</span>
            </template></el-menu-item
          > -->
          <el-menu-item index="/form/template-manage">
            <template #title>
              <el-icon>
                <Tickets />
              </el-icon>
              <span>模板</span>
            </template></el-menu-item
          >
        </el-sub-menu>

        <el-sub-menu index="2">
          <template #title>
            <el-icon><DocumentChecked /></el-icon>
            <span>指标管理</span>
          </template>
          <el-menu-item index="/index/indicatorRelease">
            <template #title>
              <el-icon><DocumentAdd /></el-icon>
              <span>指标发布</span>
            </template>
          </el-menu-item>
          <el-menu-item index="/index/indicatorView">
            <template #title>
              <el-icon>
                <DocumentCopy />
              </el-icon>
              <span>指标查看</span>
            </template>
          </el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="3">
          <template #title>
            <el-icon>
              <DocumentCopy />
            </el-icon>
            <span>统计分析</span>
          </template>
          <el-menu-item index="/statistic/horizontal">
            <template #title>
              <el-icon><OfficeBuilding /></el-icon>
              <span>多医院横向分析</span>
            </template>
          </el-menu-item>
          <el-menu-item index="/statistic/Longitudinal">
            <template #title>
              <el-icon><School /></el-icon>
              <span>单医院纵向分析</span>
            </template>
          </el-menu-item>
        </el-sub-menu>

        <el-sub-menu index="4">
          <template #title>
            <el-icon>
              <DocumentCopy />
            </el-icon>
            <span>系统管理</span>
          </template>

          <el-menu-item index="/org/manage">
            <template #title>
              <el-icon>
                <House />
              </el-icon>
              <span>医院管理</span>
            </template>
          </el-menu-item>

          <el-menu-item index="/user/manage">
            <template #title>
              <el-icon>
                <User />
              </el-icon>
              <span>质控人员管理</span>
            </template>
          </el-menu-item>

          <el-menu-item index="/user/org-manage">
            <template #title>
              <el-icon>
                <User />
              </el-icon>
              <span>医院人员管理</span>
            </template>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>

    <div class="userinfo">
      欢迎，{{ username }}
      <el-button
        type="danger"
        size="small"
        @click="logout"
        style="margin-bottom: 2pt"
        >退出</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: localStorage.getItem("username"),
      id: localStorage.getItem("id"),
    };
  },
  mounted() {
    document.addEventListener("click", this.bodyCloseMenus);
  },
  methods: {
    logout() {
      this.$router.push("/");
      localStorage.setItem("isLogined", 0);
      sessionStorage.removeItem("token");
    },
    modifyUserInfo() {
      console.log(this.id);
    },
    bodyCloseMenus(e) {
      let self = this;
      if (this.$refs.main && !this.$refs.main.contains(e.target)) {
        self.$refs.menu.close("1", []);
        self.$refs.menu.close("2", []);
        self.$refs.menu.close("3", []);
      }
    },
    beforeDestroy() {
      document.removeEventListener("click", this.bodyCloseMenus);
    },
  },
};
</script>
