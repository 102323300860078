import { createStore } from "vuex";

export default createStore({
  state: {},
  getters: {},
  mutations: {
    // SET_TOKEN: (state, token) => {
    //   state.token = token;
    //   localStorage.setItem("token", token);
    // },
    SET_TOKEN: (state, token) => {
      state.token = token;
      // localStorage.setItem("token", token);
      sessionStorage.setItem("token", token);
    },
    resetState: (state) => {
      state.token = "";
    },
  },
  actions: {},
  modules: {},
});
