import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import Layout from "../components/manage/layout/LayOut.vue";

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    component: Layout,
    beforeEnter: (to, from, next) => {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let currentTime =
        yy.toString() + "-" + mm.toString() + "-" + dd.toString();
      let sDate1 = Date.parse(localStorage.getItem("loginTime"));
      let sDate2 = Date.parse(currentTime);
      let dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      let keepLoginedTime = Math.floor(dateSpan / (24 * 3600 * 1000));

      if (localStorage.getItem("isLogined") != 1 || keepLoginedTime > 7) {
        window.location.href = "/";
      } else {
        next();
      }
    },
    children: [
      {
        path: "/form/template-manage",
        name: "TemplateManage",
        component: () => import("../components/manage/form/TemplateManage.vue"),
      },
      {
        path: "/form/add-template",
        name: "CreateTemplate",
        component: () => import("../components/manage/form/AddTemplate.vue"),
      },
      {
        path: "/form/mark-form",
        name: "MarkForm",
        component: () => import("../components/manage/form/MarkForm.vue"),
      },
      {
        path: "/form/self-form",
        name: "SlefForm",
        component: () => import("../components/manage/form/SelfForm.vue"),
      },
      {
        path: "/org/manage",
        name: "OrgManage",
        component: () => import("../components/manage/org/OrgManage.vue"),
      },
      {
        path: "/org/add",
        name: "AddOrg",
        component: () => import("../components/manage/org/AddOrg.vue"),
      },
      {
        path: "/user/manage",
        name: "UserManage",
        component: () => import("../components/manage/user/UserManage.vue"),
      },
      {
        path: "/user/org-manage",
        name: "OrgUserManage",
        component: () => import("../components/manage/user/OrgUserManage.vue"),
      },
      {
        path: "/user/org-check",
        name: "CheckOrgUser",
        component: () => import("../components/manage/user/CheckOrgUser.vue"),
      },
      {
        path: "/user/org-detail",
        name: "OrgUserDetail",
        component: () => import("../components/manage/user/OrgUserDetail.vue"),
      },
      {
        path: "/user/add",
        name: "AddUser",
        component: () => import("../components/manage/user/AddUser.vue"),
      },
      {
        path: "/user/modify",
        name: "ModifyUser",
        component: () => import("../components/manage/user/ModifyUser.vue"),
      },
      {
        path: "/statistic/horizontal",
        name: "HorizontalStatistic",
        component: () =>
          import("../components/manage/statistic/HorizontalStatistic.vue"),
      },
      {
        path: "/statistic/horizontal-result",
        name: "HorizontalResult",
        component: () =>
          import("../components/manage/statistic/HorizontalResult.vue"),
      },
      {
        path: "/statistic/Longitudinal",
        name: "LongitudinalStatistic",
        component: () =>
          import("../components/manage/statistic/LongitudinalStatistic.vue"),
      },
      {
        path: "/form/add-forms",
        name: "AddForms",
        component: () => import("../components/manage/form/AddForms.vue"),
      },
      {
        path: "/form/add-forms",
        name: "AddForms",
        component: () => import("../components/manage/form/AddForms.vue"),
      },
      //指标查看
      {
        path: "/index/indicatorRelease",
        name: "IndicatorRelease",
        component: () => import("../components/index/IndicatorRelease.vue"),
      },
      //指标发布
      {
        path: "/index/indicatorView",
        name: "IndicatorView",
        component: () => import("../components/index/IndicatorView.vue"),
      },
      //查看详情
      {
        path: "/index/viewDetails",
        name: "ViewDetails",
        component: () => import("../components/index/ViewDetails.vue"),
      },
    ],
  },
  {
    path: "/org/info",
    name: "OrgInfo",
    component: () => import("../components/manage/org/OrgInfo.vue"),
  },
  {
    path: "/statistic/get-reason",
    name: "GetReason",
    component: () => import("../components/manage/statistic/GetReason.vue"),
  },
  {
    path: "/client/get-hos-report",
    name: "getHosReport",
    component: () => import("../components/client/ExportHosReport.vue"),
  },
  {
    path: "/form/add-questions",
    name: "AddQuestions",
    component: () => import("../components/manage/form/AddQuestions.vue"),
  },
  {
    path: "/form/preview",
    name: "PreviewForm",
    component: () => import("../components/manage/form/PreviewTemplate.vue"),
  },
  {
    path: "/form/feedback",
    name: "PreviewFeedback",
    component: () => import("../components/manage/form/PreviewFeedback.vue"),
  },
  {
    path: "/user/authentication",
    name: "PreviewAuthentication",
    component: () =>
      import("../components/manage/user/PreviewAuthentication.vue"),
  },
  {
    path: "/client/form-manage",
    name: "ClientFormManage",
    beforeEnter: (to, from, next) => {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let currentTime =
        yy.toString() + "-" + mm.toString() + "-" + dd.toString();
      let sDate1 = Date.parse(localStorage.getItem("loginTime"));
      let sDate2 = Date.parse(currentTime);
      let dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      let keepLoginedTime = Math.floor(dateSpan / (24 * 3600 * 1000));

      if (localStorage.getItem("isLogined") != 1 || keepLoginedTime > 7) {
        window.location.href = "/";
      } else {
        next();
      }
    },
    component: () => import("../components/client/FormManage.vue"),
  },

  {
    path: "/client/commit-answers",
    name: "CommitAnswers",
    beforeEnter: (to, from, next) => {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let currentTime =
        yy.toString() + "-" + mm.toString() + "-" + dd.toString();
      let sDate1 = Date.parse(localStorage.getItem("loginTime"));
      let sDate2 = Date.parse(currentTime);
      let dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      let keepLoginedTime = Math.floor(dateSpan / (24 * 3600 * 1000));

      if (localStorage.getItem("isLogined") != 1 || keepLoginedTime > 7) {
        window.location.href = "/";
      } else {
        next();
      }
    },
    component: () => import("../components/client/CommitAnswers.vue"),
  },
  {
    path: "/client/info",
    name: "BasicInfo",
    component: () => import("../components/client/BasicInfo.vue"),
  },
  {
    path: "/client/result",
    name: "CheckResult",
    component: () => import("../components/client/FormResult.vue"),
  },
  {
    path: "/hospital/home",
    name: "HospitalView",
    beforeEnter: (to, from, next) => {
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth() + 1;
      let dd = new Date().getDate();
      let currentTime =
        yy.toString() + "-" + mm.toString() + "-" + dd.toString();
      let sDate1 = Date.parse(localStorage.getItem("loginTime"));
      let sDate2 = Date.parse(currentTime);
      let dateSpan = sDate2 - sDate1;
      dateSpan = Math.abs(dateSpan);
      let keepLoginedTime = Math.floor(dateSpan / (24 * 3600 * 1000));

      if (localStorage.getItem("isLogined") != 1 || keepLoginedTime > 7) {
        window.location.href = "/";
      } else {
        next();
      }
    },
    component: () => import("../components/hospital/HospitalView.vue"),
  },
  {
    path: "/hospital/register",
    name: "RegisterView",
    component: () => import("../components/hospital/RegisterView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
